'use strict';
var app = angular.module('directory');
app.factory('DateService', function ($filter) {

  /**
   * @param date - Date object, to represent the date excluding the time
   * @param hours - Hours as an integer
   * @param minutes - Minutes as an integer
   * @return {Date} A new Javascript Date object that combines the given date and time
   *
   * Taken straight from PreCallModalController.
   */
  function combineDateAndTime(date, hours, minutes) {
    var res = new Date(date.getTime());
    res.setHours(hours);
    res.setMinutes(minutes);
    res.setSeconds(0);
    return res;
  }

  /**
   * @returns {number} The offset of Eastern Time from UTC on today's date in minutes.
   *
   * Note: If we're in daylight savings time, the Eastern Time offset is 4hrs, otherwise the offset is 5hrs.
   */
  var getEasternTimeZoneOffset = function () {
    var currentYear = new Date().getFullYear();
    var june1 = new Date(currentYear + '-06-01T00:00:00Z');
    var june1Offset = june1.getTimezoneOffset(); //offset during daylight savings
    var currentOffset = new Date().getTimezoneOffset();
    var isDaylightSavings = (currentOffset === june1Offset);
    return isDaylightSavings ? (4 * 60) : (5 * 60);
  };

  /**
   * @returns {number} The offset of the current timezone from UTC on today's date in minutes.
   */
  var getCurrentTimeZoneOffset = function () {
    return new Date().getTimezoneOffset();
  };

  /**
   * @param dateStr Date as a string in the form: "2019-11-12T22:00:01.000+0000"
   * @returns {Date} The date in the param offset such that when converted to a string as of the current Time Zone, it
   * will display the time as of Easten Time.
   *
   * This is done because there's no native JS way to specify a timezone when converting dates to string, they're always
   * converted as of the current timezone. So if the client is not in Eastern Timezone, we need to offset the time for
   * it to appear as it would in Eastern Time.
   *
   * TODO: This doesn't work in Safari, and is currently not used.
   */
  var dateToEastern = function (dateStr) {
    var easternOffset = getEasternTimeZoneOffset();
    var currentOffset = getCurrentTimeZoneOffset();

    var offsetDiffMs = (easternOffset - currentOffset) * 60 * 1000;
    var dateObj = new Date(dateStr);
    return new Date(dateObj.getTime() - offsetDiffMs);
  };

  const getLongFormattedDate = function (dateTime) {
    return new Date($filter('date')(dateTime, 'MM/dd/yyyy HH:mm:ss'));
  };

  return {
    dateToEastern,
    combineDateAndTime,
    getLongFormattedDate
  };
});
